import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import path from "./const/path";
import auth from "./modules/auth";
import user from "./modules/user";
import root from "./modules/root";
import finance from "./modules/finance";
import campaign from "./modules/campaign";
import agency from "./modules/agency";
import map from "./modules/map";
import report from "./modules/report";
import creatives from "./modules/creatives";
import inventory from "./modules/inventory";
import {
  ERROR,
  ERROR_CODE,
  HTTP_DELETE,
  HTTP_GET,
  HTTP_POST,
  HTTP_PUT,
  LOADING,
  PAGINATION,
  REMOVE_ERROR,
  TOGGLE_DRAWER,
} from "./const/request";

Vue.use(Vuex);

/**
 * Функция формирования url
 * Заменяет данные, например из /user_info/:id => /user_info/1
 * @param url
 * @param params
 * @returns string
 */
const getUrl = (url, params = {}) => {
  if (!url) {
    console.error("ERROR URL", url, params);
    return Vue.prototype.$baseURL;
  }
  let path = url;
  if (Object.keys(params).length > 0) {
    Object.keys(params).forEach(function (key) {
      path = path.replace(`:${key}:`, params[key]);
    });
  }
  return Vue.prototype.$baseURL + path;
};

/**
 * Универсальный метод обработки ошибкок при запросах
 * @param e
 * @param commit
 * @param method
 * @param error
 * @returns {Promise<{response}|*>}
 */
const handleError = async (e, commit, method, error) => {
  commit(LOADING, {
    name: method,
    value: "error",
  });
  let error_message = "Что-то пошло не так, обратитесь в тех. поддержку";
  if (error) {
    error_message = error.data;
  } else if (e.response && e.response.data) {
    error_message = e.response.data.error.message;
  }
  let code = 0;
  if (e.response && e.response.status) {
    code = e.response.status;
  }
  commit(ERROR, {
    name: method,
    value: error_message,
  });
  commit(ERROR_CODE, {
    name: method,
    code: code,
  });
};

export default new Vuex.Store({
  namespaced: true,
  modules: {
    Auth: auth,
    User: user,
    Root: root,
    Finance: finance,
    Campaign: campaign,
    Agency: agency,
    Map: map,
    Report: report,
    Creatives: creatives,
    Inventory: inventory,
  },
  state: {
    loading: {},
    error: {},
    error_code: {},
    pagination: {},
    drawer: true,
  },
  getters: {
    loading: (state) => (name) => name ? state.loading[name] : state.loading,
    error: (state) => (name) => name ? state.error[name] : state.error,
    error_code: (state) => (name) =>
      name ? state.error_code[name] : state.error_code,
    pagination: (state) => (name) =>
      name ? state.pagination[name] : state.pagination,
    drawer: (state) => state.drawer,
  },
  actions: {
    /**
     * Запрос GET
     * @param state
     * @param commit
     * @param dispatch
     * @param method - константа на который будет идти запрос
     * @param params - объект с параметрами, ключ -> значение, будет добавлен ?key=value
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>>}
     */
    [HTTP_GET]: async (
      { commit, state },
      { method, params, no_commit = false, session_type = false, ...args }
    ) => {
      console.info({ log: "HTTP_GET" }, method, params, no_commit, args);
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      if (session_type) {
        config.headers["X-SESSION"] = state.Auth.session[session_type];
      }
      if (params) {
        if (params.limitPerPage === -1) {
          // проверка на показ всех элеметов таблицы(пагинация)
          delete params.page;
          delete params.limitPerPage;
        }
        config.params = params;
      }
      if (args.download) {
        config.responseType = "blob";
      }
      let replace = {};
      if (args.replace) {
        replace = args.replace;
      }
      commit(LOADING, {
        name: method,
        value: "loading",
      });
      commit(REMOVE_ERROR, method);
      try {
        let request = await axios.get(getUrl(path[method], replace), config);
        commit(LOADING, {
          name: method,
          value: "loaded",
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + "/" + method;
          }
          commit(method, { data: request.data, params });
        }
        if (request.data.currentPage) {
          commit(PAGINATION, {
            name: method,
            data: {
              currentPage: request.data.currentPage,
              limitPerPage: request.data.limitPerPage,
              resultsCount: request.data.resultsCount,
            },
          });
        }
        return request;
      } catch (e) {
        console.error("ERROR_HTTP_GET", e);
        await handleError(e, commit, method, e.response);
      }
    },

    /**
     * Запрос POST
     * @param state
     * @param commit
     * @param dispatch
     * @param method - константа на который будет идти запрос
     * @param params - параметры для замены в url
     * @param body - тело/данные запроса
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>|*>}
     */
    [HTTP_POST]: async (
      { state, commit },
      {
        method,
        params,
        body = {},
        no_commit = false,
        session_type = false,
        ...args
      }
    ) => {
      console.info({ log: "HTTP_POST" }, method, params, body, no_commit, args);
      let config = {};
      if (args.is_file) {
        config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      } else {
        config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
      }

      if (session_type) {
        config.headers["X-SESSION"] = state.Auth.session[session_type];
      }
      commit(LOADING, {
        name: method,
        value: "loading",
      });
      commit(REMOVE_ERROR, method);

      try {
        let request = await axios.post(
          getUrl(path[method], params),
          body,
          config
        );
        commit(LOADING, {
          name: method,
          value: "loaded",
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + "/" + method;
          }
          commit(method, { data: request.data, params, body });
        }
        if (request.data && request.data.currentPage) {
          commit(PAGINATION, {
            name: method,
            data: {
              currentPage: request.data.currentPage,
              limitPerPage: request.data.limitPerPage,
              resultsCount: request.data.resultsCount,
            },
          });
        }
        return request;
      } catch (e) {
        console.error("ERROR_HTTP_POST", e);
        await handleError(e, commit, method);
        return false;
      }
    },

    /**
     * Запрос PUT
     * @param state
     * @param commit
     * @param dispatch
     * @param method - константа на который будет идти запрос
     * @param params - параметры для замены в url
     * @param body - тело/данные запроса
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>|*>}
     */
    [HTTP_PUT]: async (
      { state, commit },
      { method, params, body = {}, session_type, no_commit = false, ...args }
    ) => {
      console.info({ log: "HTTP_PUT" }, method, params, body, no_commit, args);
      const config = {
        headers: {
          'x-session': state.Auth.session[session_type]
        }
      };
      commit(LOADING, {
        name: method,
        value: "loading",
      });
      commit(REMOVE_ERROR, method);
      try {
        let request = await axios.put(
          getUrl(path[method], params),
          body,
          config
        );
        commit(LOADING, {
          name: method,
          value: "loaded",
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + "/" + method;
          }
          commit(method, { data: request.data, params });
        }
        if (request.data.currentPage) {
          commit(PAGINATION, {
            name: method,
            data: {
              currentPage: request.data.currentPage,
              limitPerPage: request.data.limitPerPage,
              resultsCount: request.data.resultsCount,
            },
          });
        }
        return request;
      } catch (e) {
        console.error("ERROR_HTTP_PUT", e);
        await handleError(e, commit, method);
      }
    },

    /**
     * Запрос DELETE
     * @param state
     * @param commit
     * @param dispatch
     * @param method - константа на который будет идти запрос
     * @param params - параметры для замены в url
     * @param body - тело/данные запроса
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>|*>}
     */
    [HTTP_DELETE]: async (
      { state, commit },
      { method, params, body = {}, no_commit = false, ...args }
    ) => {
      console.info(
        { log: "HTTP_DELETE" },
        method,
        params,
        body,
        no_commit,
        args
      );
      const config = {};
      commit(LOADING, {
        name: method,
        value: "loading",
      });
      commit(REMOVE_ERROR, method);
      try {
        let request = await axios.delete(getUrl(path[method], params), config);
        commit(LOADING, {
          name: method,
          value: "loaded",
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + "/" + method;
          }
          commit(method, { data: request.data, params });
        }
        if (request.data.pagination) {
          commit(PAGINATION, { name: method, data: request.data.pagination });
        }
        return request;
      } catch (e) {
        console.error("ERROR_HTTP_DELETE", e);
        await handleError(e, commit, method);
      }
    },
  },
  mutations: {
    [LOADING]: (state, { name, value }) => {
      Vue.set(state.loading, name, value);
    },
    [ERROR]: (state, { name, value }) => {
      Vue.set(state.error, name, value);
    },
    [ERROR_CODE]: (state, { name, code }) => {
      Vue.set(state.error_code, name, code);
    },
    [REMOVE_ERROR]: (state, name) => {
      Vue.set(state.error, name, null);
    },
    [PAGINATION]: (state, { name, data }) => {
      Vue.set(state.pagination, name, data);
    },
    [TOGGLE_DRAWER]: (state, value) => {
      Vue.set(state, "drawer", value);
    },
  },
});
