import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2';
import { permissions, openInNewTab, momentJs } from './plugins/prototype'
import Notifications from 'vue-notification'
import VueTheMask from 'vue-the-mask';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import 'mapbox-gl/dist/mapbox-gl.css';

// disable console log in production
if (process.env.NODE_ENV !== 'development') {
  console.log = function () {
  }
}

if (typeof Highcharts === 'object') {
  stockInit(Highcharts);
}

Vue.use(HighchartsVue);

Vue.use(VueTheMask);

Vue.use(Notifications)
Vue.use(permissions)
Vue.use(openInNewTab)
Vue.use(momentJs)
Vue.use(VueClipboard);
Vue.config.productionTip = false
Vue.prototype.$baseURL = process.env.VUE_APP_API_URL;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
