import router from "@/router";
import { HTTP_POST } from "../const/request";
import {
  CLEAR_PREV_DATA,
  GET_TRANSACTIONS,
  SET_DATE,
  SET_ENTITY_ID,
  SET_INTERFACE_NAME,
} from "../const/finance";

export default {
  namespaced: true,
  state: {
    fin: {
      dates: [],
      transactions: [],
      money: [],
    },
    loading: false,
    interface_name: "",
    all_accounts: [],
    current_entity_id: "",
  },
  getters: {
    getDates: (state) => state.fin.dates,
    getTransactions: (state) => state.fin.transactions,
    getFilteredTransactions: (state) => {
      if (state.interface_name === "agency") {
        return state.fin.transactions.filter(
          (transaction) => transaction.account === state.current_entity_id
        );
      } else {
        return state.fin.transactions.filter(
          (transaction) => transaction.agency === state.current_entity_id
        );
      }
    },
  },
  actions: {
    [GET_TRANSACTIONS]: async ({dispatch, rootState, state, commit}, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      commit(SET_DATE, payload.date);
      let data = {
        session: rootState.Auth.session[session_type],
        from: payload.date[0],
        to: payload.date[1],
      };
      if (state.interface_name === "agency") {
        data.account = state.current_entity_id;
      } else {
        data.agency = state.current_entity_id;
      }

      await dispatch(
        HTTP_POST,
        {
          method: GET_TRANSACTIONS,
          body: data,
          session_type,
          namespace: "Finance",
        },
        { root: true }
      );
    },
  },
  mutations: {
    [SET_ENTITY_ID]: (state, data) => {
      state.current_entity_id = data;
    },
    [SET_INTERFACE_NAME]: (state, data) => {
      state.interface_name = data;
    },
    [SET_DATE]: (state, data) => {
      state.fin.dates = data;
    },
    [CLEAR_PREV_DATA]: (state) => {
      state.fin.transactions = [];
    },
    [GET_TRANSACTIONS]: (state, { data }) => {
      state.fin.transactions = data;
    },
  },
};
