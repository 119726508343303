import router from "@/router";
import {HTTP_GET, HTTP_POST, HTTP_PUT} from '../const/request';
import {
  CREATE_UPDATE_ACCOUNT,
  GET_ACCOUNT_DATA,
  SEND_MESSAGE,
  GET_SURFACE_NOIMAGES,
  SURFACE_UPLOAD_IMAGE
} from '../const/agency';

export default {
  namespaced: true,
  state: {
    account_data: [],
    surface_noimages: [],
  },
  getters: {
    getAccountData: (state) => state.account_data,
    getSurfaceNoImages: (state) => state.surface_noimages,
  },
  actions: {
    [GET_ACCOUNT_DATA]: async ({ dispatch, rootState }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_ACCOUNT_DATA,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Agency",
        },
        { root: true }
      );
    },
    [SEND_MESSAGE]: async ({ dispatch, rootState }, payload) => {
      payload.session = rootState.Auth.session.account;
      await dispatch(
        HTTP_POST,
        {
          method: SEND_MESSAGE,
          body: payload,
          session_type: "account",
          namespace: "Agency",
          no_commit: true,
        },
        { root: true }
      );
    },
    [CREATE_UPDATE_ACCOUNT]: async ({ dispatch, rootState }, payload) => {
      payload.session = rootState.Auth.session.agency;
      await dispatch(
        HTTP_POST,
        {
          method: CREATE_UPDATE_ACCOUNT,
          body: payload,
          session_type: "agency",
          namespace: "Agency",
          no_commit: true,
        },
        { root: true }
      );
    },
    [GET_SURFACE_NOIMAGES]: async ({ dispatch }) => {
      await dispatch(
        HTTP_GET,
        {
          method: GET_SURFACE_NOIMAGES,
          session_type: "agency",
          namespace: "Agency"
        },
        { root: true }
      );
    },
    [SURFACE_UPLOAD_IMAGE]: async ({ dispatch, rootState }, {id, file}) => {
      return await dispatch(
        HTTP_PUT,
        {
          method: SURFACE_UPLOAD_IMAGE,
          params: {id},
          body: file,
          session_type: "agency",
          namespace: "Agency",
          no_commit: true,
        },
        { root: true }
      );
    },
  },
  mutations: {
    [GET_ACCOUNT_DATA]: (state, { data }) => {
      state.account_data = data;
    },
    [GET_SURFACE_NOIMAGES]: (state, { data }) => {
      state.surface_noimages = data.ids;
    },
  },
};
