<template lang="pug">
	v-app
		notifications(position="top right")
		v-main
			router-view
</template>

<script>

export default {
	name: 'App',

	data: () => ({
		//
	}),
};
</script>
<style lang="scss">
@import 'assets/css/main.css';
</style>

