import cookie from "js-cookie";
import router from "@/router";
import { HTTP_POST } from "../const/request";
import {
  CHECK_SESSION,
  LOGIN,
  LOGOUT,
  NEW_MODE,
  SET_ACCESSES,
  SET_SESSION,
} from "../const/auth";

export default {
  namespaced: true,
  state: {
    session: {
      user: sessionStorage.getItem('userSession') || null,
      root: sessionStorage.getItem('rootSession') || null,
      agency: sessionStorage.getItem('agencySession') || null,
      account: sessionStorage.getItem('accountSession') || null,
    },
    location: {
      user: sessionStorage.getItem('userLocation') || null,
      root: sessionStorage.getItem('rootLocation') || null,
      agency: sessionStorage.getItem('agencyLocation') || null,
      account: sessionStorage.getItem('accountLocation') || null,
    },
    accesses: sessionStorage.getItem('accesses') ? JSON.parse(sessionStorage.getItem('accesses')) : [],
    current_location: sessionStorage.getItem('currentLocation') || null,
    permissions: sessionStorage.getItem('permissions') ? JSON.parse(sessionStorage.getItem('permissions')) : [],
  },
  getters: {
    getAccesses: (state) => state.accesses,
    getSession: (state) => state.session,
    getPermissions: (state) => state.permissions,
    getCurrentLocation: (state) => state.current_location,
  },
  actions: {
    [LOGIN]: async ({ dispatch, commit, rootState }, { login, password }) => {
      const result = await dispatch(
        HTTP_POST,
        {
          method: LOGIN,
          body: { login, password },
          namespace: "Auth",
          no_commit: true,
        },
        { root: true }
      );
      if (rootState.loading[LOGIN] !== "error") {
        commit(SET_SESSION, {
          session: result.data.session,
          type: result.data.access,
        });
        commit(SET_ACCESSES, { data: result.data });
      }
    },
    [NEW_MODE]: async ({ dispatch, state, commit }, { id, type, session_type = 'user' }) => {
      await dispatch(
        HTTP_POST,
        {
          method: NEW_MODE,
          body: { session: state.session[session_type], id, type },
          session_type: session_type,
          namespace: "Auth",
        },
        { root: true }
      );
    },
    [CHECK_SESSION]: async ({ dispatch, state, rootState, commit }) => {
      try {
        const session_type = router.currentRoute.meta.session_type;
        await dispatch(
            HTTP_POST,
            {
              method: CHECK_SESSION,
              body: { session: state.session[session_type] },
              session_type,
              namespace: "Auth",
            },
            { root: true }
        );
        if (rootState.loading[CHECK_SESSION] === "error") {
          router.push("/login/?sessionIsDead=true");
        }
      } catch (e) {
        router.push("/login/?sessionIsDead=true");
      }
    },
  },
  mutations: {
    [SET_SESSION]: (state, { session, type }) => {
      state.session[type] = session;
      sessionStorage.setItem(`${type}Session`, session);
      cookie.set("session", session);
    },
    [SET_ACCESSES]: (state, { data }) => {
      if (data.accesses) {
        let all_accesses = {
          admin: [],
          agency: [],
          account: [],
          dash: [],
          dmp: [],
          report: [],
        };
        let accessesKey = Object.keys(data.accesses.list);
        accessesKey.forEach((key) => {
          data.accesses.list[key].id = key;
        });
        let objAccesses = Object.values(data.accesses.list);
        objAccesses.map((access) => {
          if (Object.keys(all_accesses).indexOf(access.group) !== -1) {
            all_accesses[access.group].push(access);
          } else {
            console.log("Ошибка");
          }
        });
        sessionStorage.setItem("accesses", JSON.stringify(all_accesses));
        state.accesses = all_accesses;
      }
    },
    [NEW_MODE]: (state, { data, body }) => {
      state.session[data.access] = data.session;
      state.location[data.access] = body.id;
      state.current_location = body.id;
      sessionStorage.setItem(`${data.access}Session`, data.session);
      sessionStorage.setItem(`${data.access}Location`, body.id);
      sessionStorage.setItem("currentLocation", body.id);
    },
    [CHECK_SESSION]: (state, { data }) => {
      state.permissions = data.permissions;
      sessionStorage.setItem("permissions", JSON.stringify(data.permissions));
    },
    [LOGOUT]: (state) => {
      state.session = {
        user: null,
        root: null,
        agency: null,
        account: null,
      };
      sessionStorage.clear();
    },
  },
};
