import { HTTP_POST } from "../const/request";
import router from "@/router";
import {
  ACCOUNTS,
  DICTIONARY_AGENCY,
  AGENCY_DATA,
  AGENCY_TRANSFER_MONEY,
  CREATE_EDIT_AGENCY,
  PROFILE,
  SUMMARY_INFO,
} from "../const/root";

const SortByField = (field) => (a, b) => {
  if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
  if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
  return 0;
};

export default {
  namespaced: true,
  state: {
    info: {},
    agencies: [],
    summary: {
      data: [],
      agency: "",
      account: "",
      dates: [],
    },
    all_accounts: [],
    agency_dictionary: [],
  },
  getters: {
    getProfile: (state) => state.profile,
    getAgencies: (state) => state.agencies,
    getAllAccounts: (state) => state.all_accounts,
    getAgencyDictionary: (state) => state.agency_dictionary,
    getSummary: (state) => state.summary,
    getInfo: (state) => state.info,
  },
  actions: {
    [PROFILE]: async ({ dispatch, rootState }, session_type) => {
      session_type = session_type || "root";
      await dispatch(
        HTTP_POST,
        {
          method: PROFILE,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Root",
        },
        { root: true }
      );
    },
    [AGENCY_DATA]: async ({ dispatch, rootState, commit }) => {
      commit(AGENCY_DATA, { data: [] });
      await dispatch(
        HTTP_POST,
        {
          method: AGENCY_DATA,
          body: { session: rootState.Auth.session.root },
          session_type: "root",
          namespace: "Root",
        },
        { root: true }
      );
    },
    [SUMMARY_INFO]: async ({ dispatch, rootState, commit }, payload) => {
      payload.session_type =
        payload && payload.hasOwnProperty("session_type")
          ? payload.session_type
          : "root";
      let params = {
        session: rootState.Auth.session[payload.session_type],
        from: payload.date.start,
        to: payload.date.stop,
        campaigntype: payload.campaigntype,
      };
      if (payload.agency != null && payload.agency !== "") {
        params.agency = payload.agency;
      }
      if (payload.account != null && payload.account !== "") {
        params.account = payload.account;
      }
      await dispatch(
        HTTP_POST,
        {
          method: SUMMARY_INFO,
          body: params,
          session_type: payload.session_type,
          namespace: "Root",
        },
        { root: true }
      );
    },
    [ACCOUNTS]: async ({ dispatch, rootState, commit }, payload) => {
      let agency = payload && payload.agency;
      let session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: ACCOUNTS,
          body: { agency, session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Root",
        },
        { root: true }
      );
    },
    [CREATE_EDIT_AGENCY]: async ({ dispatch, rootState, commit }, payload) => {
      payload.session = rootState.Auth.session.root;
      payload.charge = parseFloat(payload.charge);
      await dispatch(
        HTTP_POST,
        {
          method: CREATE_EDIT_AGENCY,
          body: payload,
          session_type: "root",
          no_commit: true,
          namespace: "Root",
        },
        { root: true }
      );
    },
    [AGENCY_TRANSFER_MONEY]: async ({ dispatch, rootState, commit }, payload) => {
      let params = {
        session: rootState.Auth.session[router.currentRoute.meta.session_type],
        money: parseFloat(payload.money),
      };
      if (router.currentRoute.meta.session_type === "agency") {
        params.account = payload.id;
      }
      if (router.currentRoute.meta.session_type === "root") {
        params.agency = payload.id;
      }
      await dispatch(
        HTTP_POST,
        {
          method: AGENCY_TRANSFER_MONEY,
          body: params,
          session_type: payload.session_type,
          namespace: "Root",
          no_commit: true,
        },
        { root: true }
      );
    },
    [DICTIONARY_AGENCY]: async ({ dispatch, rootState }) => {
      await dispatch(
        HTTP_POST,
        {
          method: DICTIONARY_AGENCY,
          body: { session: rootState.Auth.session.root },
          session_type: "root",
          namespace: "Root",
        },
        { root: true }
      );
    },
  },
  mutations: {
    [PROFILE]: (state, { data }) => {
      state.info = data;
    },
    [AGENCY_DATA]: (state, { data }) => {
      data = data.sort(SortByField("name"));
      state.agencies = data;
    },
    [SUMMARY_INFO]: (state, { data }) => {
      state.summary.data = data && data.providers;
    },
    [ACCOUNTS]: (state, { data }) => {
      data = data.sort(SortByField("name"));
      state.all_accounts = data;
    },
    [DICTIONARY_AGENCY]: (state, { data }) => {
      data = data.sort(SortByField("name"));
      state.agency_dictionary = data;
    },
  },
};
