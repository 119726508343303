import router from "@/router";
import {HTTP_POST, HTTP_PUT} from '../const/request';
import {
  ADD_CAMPAIGN_SURFACE,
  BUYING_TYPES,
  CAMPAIGN_TYPES,
  COPY_CAMPAIGN,
  EDIT_CAMPAIGN_GROUP,
  GET_BID_TYPES,
  GET_CAMPAIGN,
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_META,
  GET_CAMPAIGN_STATE,
  GET_CAMPAIGN_SURFACES_STATS,
  GET_CAMPAIGN_STAT_HOURS,
  GET_CAMPAIGN_STRATEGIES,
  REMOVE_CAMPAIGN_SURFACE,
  SET_CALENDAR_DATA,
  SET_CAMPAIGN_CREATIVE,
  SET_CAMPAIGN_GROUP,
  SET_CAMPAIGN_STATUS,
  SET_CURRENT_CAMPAIGN,
  SET_CAMPAIGN_ARCHIVE,
  SET_SURFACES,
  SET_UNITS,
  SET_DATE,
  CLEAR_CALENDAR_DATA,
  GET_CAMPAIGN_STATS,
  CREATE_CAMPAIGN,
  EDIT_CAMPAIGN,
  GET_AUDIENCE,
  CLEAR_CAMPAIGN_STATE,
  CLEAR_CAMPAIGN,
  CLEAR_CAMPAIGN_SURFACES,
  SET_CAMPAIGN_STRATEGY,
  CLEAR_CAMPAIGNS,
  GET_CBU_SAMPLE_RATE
} from "../const/campaign";

export default {
  namespaced: true,
  state: {
    types: [],
    bid_types: [],
    campaigns: [],
    tableData: [],
    groups: [],
    surfaces: [],
    campaign: {
      raisingCof: 0,
      name: "",
      surfaces: [],
      cpm: 0,
      income: [],
      gender: "",
      limit: {
        totalBudget: 0,
      },
      audience: {
        sex: [],
        income: [],
      },
      state: {
        trp: 0,
      },
    },
    campaignState: {
      win: 0,
      dayBudget: 0,
      request: 0,
      budget: 0,
      trp: 0,
    },
    status: {},
    buyingTypes: [],
    surfacesStats: [],
    bidStrategies: [],
    campaignHours: [],
    weekDays: null,
    date: null,
    stats: null,
    strategiesMode: "",
    inventorySurfaces: [],
    efficiencySampleRate: []
  },
  getters: {
    getTypes: (state) => state.types,
    getCampaigns: (state) => state.campaigns,
    getBidTypes: (state) => state.bid_types,
    getTableData: (state) => state.tableData,
    getCampaign: (state) => state.campaign,
    getCampaignState: (state) => state.campaignState,
    customGroups: (state) => {
      return state.groups.map((group) => {
        return {
          id: group,
          label: group !== "" ? group : "Без группы",
        };
      });
    },
    getGroups: (state) => state.groups,
    getBuyingTypes: (state) => state.buyingTypes,
    getSelectedSurfaces: (state) => state.surfaces,
    getSurfacesStats: (state) => state.surfacesStats,
    getWeekDays: (state) => state.weekDays,
    getStats: (state) => state.stats,
    getBidStrategies: (state) => state.bidStrategies,
    getCampaignHours: (state) => state.campaignHours,
    getStrategiesMode: (state) => state.strategiesMode,
    getInventorySurfaces: (state) => state.inventorySurfaces,
    getEfficiencySampleRate: (state) => state.efficiencySampleRate
  },
  actions: {
    [CAMPAIGN_TYPES]: async ({ dispatch, rootState }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: CAMPAIGN_TYPES,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [GET_BID_TYPES]: async ({ dispatch, rootState }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_BID_TYPES,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [GET_CAMPAIGN_LIST]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN_LIST,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [SET_CAMPAIGN_STATUS]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: SET_CAMPAIGN_STATUS,
          body: payload,
          session_type,
          namespace: "Campaign",
          no_commit: true,
        },
        { root: true }
      );
    },
    [SET_CAMPAIGN_ARCHIVE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: SET_CAMPAIGN_ARCHIVE,
          body: payload,
          session_type,
          namespace: "Campaign",
          no_commit: true,
        },
        { root: true }
      );
    },
    [SET_CAMPAIGN_GROUP]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: SET_CAMPAIGN_GROUP,
          body: payload,
          session_type,
          namespace: "Campaign",
          no_commit: true,
        },
        { root: true }
      );
    },
    [EDIT_CAMPAIGN_GROUP]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: EDIT_CAMPAIGN_GROUP,
          body: payload,
          session_type,
          namespace: "Campaign",
          no_commit: true,
        },
        { root: true }
      );
    },
    [SET_CAMPAIGN_CREATIVE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: SET_CAMPAIGN_CREATIVE,
          body: payload,
          session_type,
          namespace: "Campaign",
          no_commit: true,
        },
        { root: true }
      );
    },
    [GET_CAMPAIGN]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [GET_CAMPAIGN_META]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN_META,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [GET_CAMPAIGN_STATE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN_STATE,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [COPY_CAMPAIGN]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: COPY_CAMPAIGN,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [BUYING_TYPES]: async ({ dispatch, rootState }, payload = "account") => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: BUYING_TYPES,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [SET_SURFACES]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: SET_SURFACES,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [GET_CAMPAIGN_SURFACES_STATS]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN_SURFACES_STATS,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [GET_CAMPAIGN_STATS]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN_STATS,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [CREATE_CAMPAIGN]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      return await dispatch(
        HTTP_POST,
        {
          method: CREATE_CAMPAIGN,
          body: payload,
          session_type,
          namespace: "Campaign",
          no_commit: true,
        },
        {
          root: true,
        }
      );
    },
    [EDIT_CAMPAIGN]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: EDIT_CAMPAIGN,
          body: payload,
          session_type,
          namespace: "Campaign",
          no_commit: true,
        },
        {
          root: true,
        }
      );
    },
    [GET_AUDIENCE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_AUDIENCE,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        {
          root: true,
        }
      );
    },
    [GET_CAMPAIGN_STRATEGIES]: async ({ dispatch, rootState }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN_STRATEGIES,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Campaign",
        },
        {
          root: true,
        }
      );
    },
    [GET_CAMPAIGN_STAT_HOURS]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CAMPAIGN_STAT_HOURS,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
    [GET_CBU_SAMPLE_RATE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      return await dispatch(
        HTTP_PUT,
        {
          method: GET_CBU_SAMPLE_RATE,
          body: payload,
          session_type,
          namespace: "Campaign",
        },
        { root: true }
      );
    },
  },
  mutations: {
    [COPY_CAMPAIGN]: (state, { data }) => {
      state.status = data;
    },
    [CAMPAIGN_TYPES]: (state, { data }) => {
      state.types = data;
    },
    [GET_CAMPAIGN_STATE]: (state, { data }) => {
      state.campaignState = data;
    },
    [GET_BID_TYPES]: (state, { data }) => {
      state.bid_types = data;
    },
    [GET_CAMPAIGN_LIST]: (state, { data, body }) => {
      if (data === null) {
        return;
      }
      data = data.map((item) => {
        if (item.bidType === "rating" && item.state) item.state.win = 0;
        return item;
      });
      state.campaigns = data;
      let groups = [];
      let tableData = [];
      if (data !== null) {
        groups = data
          .map((a) => {
            return a.group;
          })
          .filter((val, i, ar) => ar.indexOf(val) === i)
          .sort();
        tableData = groups.map((a) => {
          const prevItem =
            state.tableData &&
            state.tableData[
              state.tableData.findIndex((i) => i.groupName === a)
            ];
          return {
            groupName: a,
            show: prevItem ? prevItem.show : false,
            data: data
              .filter((item) => item.group === a)
              .map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  group: item.group,
                  status: item.status,
                  statusDisplay() {
                    switch (this.status) {
                      case "active":
                        return true;
                      case "stopped":
                        return false;
                      case "paused":
                        return true;
                    }
                  },
                  win: item.state ? item.state.win : 0,
                  budget: item.state ? item.state.budget : 0,
                  limit: item.limit,
                  ots: item.state ? item.state.ots : 0,
                  grp: item.state ? item.state.grp : 0,
                  trp: item.state ? item.state.trp : 0,
                  balance:
                    item.limit.totalBudget -
                    (item.state ? item.state.budget : 0),
                  bidType: item.bidType,
                };
              }),
          };
        });
      }
      state.groups = groups;
      if (body.group || body.group === "") {
        state.tableData = tableData.filter((a) => a.groupName === body.group);
      } else {
        state.tableData = tableData;
      }
    },
    [GET_CAMPAIGN]: (state, { data }) => {
      if (data.state === null) data.state = {};
      data.audience = {
        sex: [],
        income: [],
      };
      state.campaign = {
        ...data,
        statusDisplay() {
          switch (this.status) {
            case "active":
              return true;
            case "stopped":
              return false;
            case "paused":
              return true;
          }
        },
      };
    },
    [GET_CAMPAIGN_META]: (state, { data }) => {
      if (!data.error) {
        state.campaign.calendar = data.calendar;
        if (data.calendar.dates && data.calendar.dates[0]) {
          let range = data.calendar.dates[0];
          state.campaign.periodFrom = new Date(range.from).getTime();
          state.campaign.periodTo = new Date(range.to).getTime();
        }
        if (data.calendar.week_days) {
          state.weekDays = data.calendar.week_days;
        }
      } else {
        state.campaign.calendar = {
          dates: [
            {
              from: new Date(new Date().setDate(new Date().getDate() - 7)),
              to: new Date(),
            },
          ],
        };
      }
    },
    [SET_CURRENT_CAMPAIGN]: (state, payload) => {
      state.campaign = payload;
    },
    [BUYING_TYPES]: (state, { data }) => {
      state.buyingTypes = data;
    },
    [SET_SURFACES]: (state, { data }) => {
      state.surfaces = data && data.surfaces;
      state.inventorySurfaces = data;
    },
    [ADD_CAMPAIGN_SURFACE](state, payload) {
      const surfaces = [].concat(state.surfaces) || [];
      surfaces.push(payload);
      state.surfaces = surfaces;
    },
    [REMOVE_CAMPAIGN_SURFACE](state, payload) {
      state.surfaces = state.surfaces.filter(
        (surface) => surface.id !== payload.id
      );
    },
    [SET_UNITS](state, payload) {
      state.campaign.units = payload;
    },
    [GET_CAMPAIGN_SURFACES_STATS](state, { data }) {
      state.surfacesStats = data.statistics;
      if (state.surfaces.length > 0 && data !== null) {
        for (const surface of data.statistics) {
          state.surfaces[
            state.surfaces.findIndex((unit) => unit.id == surface.surfaceId)
          ].rating = surface.rating;
        }
      }
    },
    [SET_CALENDAR_DATA](state, payload) {
      state.weekDays = payload;
    },
    [CLEAR_CALENDAR_DATA](state) {
      state.weekDays = null;
    },
    [CLEAR_CAMPAIGN_STATE](state) {
      state.campaignState = {
        win: 0,
        dayBudget: 0,
        request: 0,
        budget: 0,
        trp: 0,
      };
    },
    [CLEAR_CAMPAIGNS](state) {
      state.campaigns = [];
    },
    [CLEAR_CAMPAIGN](state) {
      let clear_state = {
        raisingCof: 0,
        name: "",
        surfaces: [],
        cpm: 0,
        income: [],
        gender: "",
        limit: {
          totalBudget: 0,
        },
        audience: {
          sex: [],
          income: [],
        },
        state: {
          trp: 0,
        },
      };
      state.campaign = clear_state;
    },
    [CLEAR_CAMPAIGN_SURFACES](state) {
      state.surfaces = [];
    },
    [SET_DATE](state, payload) {
      state.date = payload;
    },
    [GET_CAMPAIGN_STATS](state, { data }) {
      state.stats = data;
    },
    [GET_AUDIENCE](state, { data }) {
      if (data.sex === "all") data.sex = ["male", "female"];
      else {
        data.sex = [data.sex];
      }
      data.income = data.income.split("");
      state.campaign.audience = data;
    },
    [GET_CAMPAIGN_STRATEGIES]: (state, { data }) => {
      state.bidStrategies = data;
    },
    [GET_CAMPAIGN_STAT_HOURS]: (state, { data }) => {
      state.campaignHours = data;
    },
    [SET_CAMPAIGN_STRATEGY]: (state, payload) => {
      state.strategiesMode = payload;
    },
    [GET_CBU_SAMPLE_RATE]: (state, { data }) => {
      state.efficiencySampleRate = data.response;
    },
  },
};
