import router from "@/router";
import { HTTP_POST } from "../const/request";
import {
  CREATE_INVENTORY,
  DELETE_INVENTORY,
  GET_INVENTORY_BY_ID,
  GET_INVENTORY_LIST,
  UPDATE_INVENTORY,
  GET_INVENTORY_STATUS,
  CLEAR_INVENTORY_STATUS,
  CLEAR_INVENTORY_BY_ID,
} from "../const/inventory";

export default {
  namespaced: true,
  state: {
    inventoryList: [],
    items: [],
    inventory: {},
    status: {},
  },
  getters: {
    getInventory: (state) => state.inventory,
    getInventoryList: (state) => state.inventoryList,
    getItems: (state) => state.items,
    getInventoryStatus: (state) => state.status,
  },
  actions: {
    [GET_INVENTORY_LIST]: async ({ dispatch }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_INVENTORY_LIST,
          session_type,
          namespace: "Inventory",
        },
        { root: true }
      );
    },
    [GET_INVENTORY_BY_ID]: async ({dispatch, rootState, state, commit}, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_INVENTORY_BY_ID,
          body: payload,
          session_type,
          namespace: "Inventory",
        },
        { root: true }
      );
    },
    [CREATE_INVENTORY]: async ({dispatch, rootState, state, commit}, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: CREATE_INVENTORY,
          body: payload,
          session_type,
          namespace: "Inventory",
          no_commit: true,
        },
        { root: true }
      );
    },
    [UPDATE_INVENTORY]: async ({dispatch, rootState, state, commit}, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: UPDATE_INVENTORY,
          body: payload,
          session_type,
          namespace: "Inventory",
          no_commit: true,
        },
        { root: true }
      );
    },
    [DELETE_INVENTORY]: async ({dispatch, rootState, state, commit}, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: DELETE_INVENTORY,
          body: payload,
          session_type,
          namespace: "Inventory",
          no_commit: true,
        },
        { root: true }
      );
    },
    [GET_INVENTORY_STATUS]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_INVENTORY_STATUS,
          body: payload,
          session_type,
          namespace: "Inventory",
        },
        { root: true }
      );
    },
  },
  mutations: {
    [GET_INVENTORY_LIST]: (state, { data }) => {
      state.inventoryList = data;
      let items = [];
      if (data !== null)
        items = data.map((a) => {
          let date = new Date(Date.parse(a.created)),
            dd = date.getDate(),
            mm = date.getMonth() + 1,
            yy = date.getFullYear();
          if (dd < 10) dd = "0" + dd;
          if (mm < 10) mm = "0" + mm;
          if (yy < 10) yy = "0" + yy;
          a.created = `${dd}.${mm}.${yy}`;
          return a;
        });
      state.items = items;
    },
    [GET_INVENTORY_BY_ID]: (state, { data }) => {
      state.inventory = data;
    },
    [GET_INVENTORY_STATUS]: (state, { data }) => {
      state.status = data;
    },
    [CLEAR_INVENTORY_STATUS](state) {
      state.status = {};
    },
    [CLEAR_INVENTORY_BY_ID](state) {
      state.inventory = {};
    },
  },
};
