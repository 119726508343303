import { HTTP_POST } from "../const/request";
import { CLEAR_ANALYTICS, GET_ANALYTICS, GET_FORECAST } from "../const/report";

let initialValue = 0;

export default {
  namespaced: true,
  state: {
    analytics: [],
  },
  getters: {
    getAnalytics: (state) => state.analytics,
    getReducedAnalytics: (state) => (raisingCof) => state.analytics.reduce(
      (accumulator, currentValue) => {
        const bigFloor = (currentValue.avgBidFloor + (currentValue.avgBidFloor / 100) * raisingCof).toFixed(2)
        return accumulator + (bigFloor * currentValue.count)
      },
      initialValue
    ),
  },
  actions: {
    [GET_ANALYTICS]: async ({ dispatch, rootState }, payload) => {
      await dispatch(
        HTTP_POST,
        {
          method: GET_ANALYTICS,
          body: payload,
          session_type: payload.session_type || "account",
          namespace: "Report",
        },
        { root: true }
      );
    },
    [GET_FORECAST]: async ({ dispatch, rootState }, payload) => {
      await dispatch(
        HTTP_POST,
        {
          method: GET_FORECAST,
          body: payload,
          session_type: payload.session_type || "account",
          namespace: "Report",
        },
        { root: true }
      );
    },
  },
  mutations: {
    [GET_ANALYTICS]: (state, { data }) => {
      state.analytics = data;
    },
    [CLEAR_ANALYTICS]: (state) => {
      state.analytics = {};
    },
    [GET_FORECAST]: (state, { data }) => {
      state.analytics = data;
    },
  },
};
