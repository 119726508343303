export const HTTP_GET = "HTTP_GET";
export const HTTP_POST = "HTTP_POST";
export const HTTP_PUT = "HTTP_PUT";
export const HTTP_DELETE = "HTTP_DELETE";
export const PAGINATION = "PAGINATION";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const ERROR_CODE = "ERROR_CODE";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
