import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#5B5B5B', //light-grey
        secondary: '#3C3C3B', //dark-grey
        accent: '#485CAA', //blue
        error: '#E5332A', //red
        fontcolor: '#FFFFFF', //white
      },
      light: {
        primary: '#5B5B5B', //light-grey
        secondary: '#3C3C3B', //dark-grey
        accent: '#485CAA', //blue
        error: '#E5332A', //red
        fontcolor: '#FFFFFF', //white
      },
    },
  },
});
