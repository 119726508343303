import { CHECK_SESSION, LOGIN, NEW_MODE } from "./auth";
import {
  ACTIVATE_USER,
  CHANGE_PASSWORD,
  CREATE_NEW_USER,
  GET_ROLES,
  GET_USERS,
  PENDING_USERS,
  REMOVE_USER,
  UPDATE_USER,
  UPDATE_USER_ACCESS,
  USER_INFO,
} from "./user";
import {
  ACCOUNTS,
  DICTIONARY_AGENCY,
  AGENCY_DATA,
  AGENCY_TRANSFER_MONEY,
  CREATE_EDIT_AGENCY,
  PROFILE,
  SUMMARY_INFO,
} from "./root";
import { GET_TRANSACTIONS } from "./finance";
import {
  CREATE_UPDATE_ACCOUNT,
  GET_ACCOUNT_DATA,
  SEND_MESSAGE,
  GET_SURFACE_NOIMAGES,
  SURFACE_UPLOAD_IMAGE
} from './agency';
import {
  GET_CITIES,
  GET_PLATFORM_TYPES,
  GET_PUBLISHERS,
  GET_SURFACE_STATS,
  GET_UNITS,
} from "./map";
import {
  CAMPAIGN_TYPES,
  GET_BID_TYPES,
  GET_CAMPAIGN_LIST,
  SET_CAMPAIGN_GROUP,
  SET_CAMPAIGN_STATUS,
  EDIT_CAMPAIGN_GROUP,
  GET_CAMPAIGN,
  GET_CAMPAIGN_META,
  SET_CAMPAIGN_CREATIVE,
  GET_CAMPAIGN_STATE,
  COPY_CAMPAIGN,
  BUYING_TYPES,
  SET_SURFACES,
  SET_CAMPAIGN_ARCHIVE,
  GET_CAMPAIGN_SURFACES_STATS,
  GET_CAMPAIGN_STATS,
  GET_CAMPAIGN_STAT_HOURS,
  GET_CAMPAIGN_STRATEGIES,
  CREATE_CAMPAIGN,
  EDIT_CAMPAIGN,
  GET_AUDIENCE,
  GET_CBU_SAMPLE_RATE
} from "./campaign";
import { GET_ANALYTICS, GET_FORECAST } from "./report";
import {
  GET_CREATIVE_LIST,
  UPDATE_CREATIVE,
  DELETE_CREATIVE,
  CREATE_CREATIVE,
  DEACTIVATE_CREATIVE,
  FILE_UPLOAD_CREATIVE,
  SET_CREATIVE_STATUS,
  GET_CREATIVE_STATUSES,
} from "./creatives";
import {
  GET_INVENTORY_LIST,
  GET_INVENTORY_BY_ID,
  CREATE_INVENTORY,
  DELETE_INVENTORY,
  UPDATE_INVENTORY,
  GET_INVENTORY_STATUS,
} from "./inventory";

const path = {
  [LOGIN]: "jsonapi/login",
  [USER_INFO]: "jsonapi/user/info",
  [NEW_MODE]: "jsonapi/user/new_mode",
  [CHECK_SESSION]: "jsonapi/checksession",
  [PROFILE]: "jsonapi/profile",
  [AGENCY_DATA]: "jsonapi/admin/getagencydata",
  [GET_ACCOUNT_DATA]: "jsonapi/agency/getaccountdata",
  [SUMMARY_INFO]: "jsonapi/finance/summaryinfo",
  [ACCOUNTS]: "jsonapi/dictionary/accounts",
  [CREATE_EDIT_AGENCY]: "jsonapi/admin/updateagency",
  [AGENCY_TRANSFER_MONEY]: "jsonapi/finance/transfermoney",
  [GET_ROLES]: "jsonapi/usermanager/available_roles",
  [GET_USERS]: "jsonapi/usermanager/list",
  [CREATE_NEW_USER]: "jsonapi/usermanager/new_user",
  [REMOVE_USER]: "jsonapi/usermanager/delete_user",
  [UPDATE_USER]: "jsonapi/usermanager/update_user",
  [UPDATE_USER_ACCESS]: "jsonapi/usermanager/update_user_access",
  [CHANGE_PASSWORD]: "jsonapi/user/login/change_pass",
  [PENDING_USERS]: "jsonapi/usermanager/pending_users",
  [DICTIONARY_AGENCY]: "jsonapi/dictionary/agency",
  [GET_TRANSACTIONS]: "jsonapi/finance/transactions",
  [SEND_MESSAGE]: "jsonapi/sendmessage",
  [GET_CITIES]: "jsonapi/ddp/cities",
  [GET_PLATFORM_TYPES]: "jsonapi/ddp/surfacetypes",
  [GET_PUBLISHERS]: "jsonapi/ddp/publishers",
  [GET_BID_TYPES]: "jsonapi/dictionary/bidtypes",
  [CAMPAIGN_TYPES]: "jsonapi/dictionary/campaigntypes",
  [GET_CAMPAIGN_LIST]: "jsonapi/campaign/list",
  [SET_CAMPAIGN_STATUS]: "jsonapi/campaign/setstatus",
  [SET_CAMPAIGN_GROUP]: "jsonapi/campaign/setgroup",
  [GET_CAMPAIGN_STRATEGIES]: "jsonapi/dictionary/bidstrategies",
  [EDIT_CAMPAIGN_GROUP]: "jsonapi/group/edit",
  [GET_ANALYTICS]: "jsonapi/statistics/state",
  [GET_UNITS]: "jsonapi/surfaces",
  [CREATE_UPDATE_ACCOUNT]: "jsonapi/agency/updateaccount",
  [GET_CREATIVE_LIST]: "jsonapi/creative/getlist",
  [UPDATE_CREATIVE]: "jsonapi/creative/update",
  [DELETE_CREATIVE]: "jsonapi/creative/delete",
  [CREATE_CREATIVE]: "jsonapi/creative/create",
  [FILE_UPLOAD_CREATIVE]: "jsonapi/creative/fileupload",
  [DEACTIVATE_CREATIVE]: "jsonapi/creative/deactivate",
  [GET_INVENTORY_LIST]: "jsonapi/inventory/list",
  [GET_INVENTORY_BY_ID]: "jsonapi/inventory/getbyid",
  [CREATE_INVENTORY]: "jsonapi/inventory/create",
  [DELETE_INVENTORY]: "jsonapi/inventory/delete",
  [UPDATE_INVENTORY]: "jsonapi/inventory/update",
  [GET_CAMPAIGN]: "jsonapi/campaign/get",
  [GET_CAMPAIGN_META]: "jsonapi/campaign/getmeta",
  [SET_CREATIVE_STATUS]: "jsonapi/creative/changestatus",
  [SET_CAMPAIGN_CREATIVE]: "jsonapi/creative/setcampaign",
  [GET_CREATIVE_STATUSES]: "jsonapi/creative/status",
  [GET_CAMPAIGN_STATE]: "jsonapi/campaign/getstate",
  [GET_CAMPAIGN_STAT_HOURS]: "jsonapi/statistics/campaign",
  [SET_CAMPAIGN_ARCHIVE]: "jsonapi/campaign/archive",
  [COPY_CAMPAIGN]: "jsonapi/campaign/copy",
  [BUYING_TYPES]: "jsonapi/dictionary/buyingtypes",
  [GET_SURFACE_STATS]: "jsonapi/dmp/getsurfacesstats",
  [GET_CAMPAIGN_SURFACES_STATS]: "jsonapi/dmp/getsurfacesstats",
  [SET_SURFACES]: "jsonapi/campaign/get_inventory",
  [GET_FORECAST]: "jsonapi/statistics/forecast",
  [GET_CAMPAIGN_STATS]: "jsonapi/dmp/getcampaignstats",
  [CREATE_CAMPAIGN]: "jsonapi/campaign/create",
  [EDIT_CAMPAIGN]: "jsonapi/campaign/edit",
  [ACTIVATE_USER]: "jsonapi/user/activate/save_pass",
  [GET_INVENTORY_STATUS]: "jsonapi/inventory/status",
  [GET_SURFACE_NOIMAGES]: "jsonapi/surface/noimages",
  [SURFACE_UPLOAD_IMAGE]: "jsonapi/surface/image/:id:",
  [GET_CBU_SAMPLE_RATE]: "jsonapi/programmatic/cbu/sample-rate",
};
export default path;
