export const CAMPAIGN_TYPES = 'CAMPAIGN_TYPES'
export const GET_BID_TYPES = 'GET_BID_TYPES'
export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST'
export const SET_CAMPAIGN_STATUS = 'SET_CAMPAIGN_STATUS'
export const SET_CAMPAIGN_GROUP = 'SET_CAMPAIGN_GROUP'
export const SET_CAMPAIGN_ARCHIVE = 'SET_CAMPAIGN_ARCHIVE'
export const EDIT_CAMPAIGN_GROUP = 'EDIT_CAMPAIGN_GROUP'
export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CAMPAIGN_META = 'GET_CAMPAIGN_META'
export const SET_CAMPAIGN_CREATIVE = 'SET_CAMPAIGN_CREATIVE'
export const GET_CAMPAIGN_STATE = 'GET_CAMPAIGN_STATE'
export const GET_CAMPAIGN_STRATEGIES = 'GET_CAMPAIGN_STRATEGIES'
export const COPY_CAMPAIGN = 'COPY_CAMPAIGN'
export const SET_CURRENT_CAMPAIGN = 'SET_CURRENT_CAMPAIGN'
export const BUYING_TYPES = 'BUYING_TYPES'
export const SET_SURFACES = 'SET_SURFACES'
export const ADD_CAMPAIGN_SURFACE = 'ADD_CAMPAIGN_SURFACE'
export const REMOVE_CAMPAIGN_SURFACE = 'REMOVE_CAMPAIGN_SURFACE'
export const SET_UNITS = 'SET_UNITS'
export const GET_CAMPAIGN_SURFACES_STATS = 'GET_CAMPAIGN_SURFACES_STATS'
export const SET_CALENDAR_DATA = 'SET_CALENDAR_DATA'
export const SET_DATE = 'SET_DATE'
export const CLEAR_CALENDAR_DATA = 'CLEAR_CALENDAR_DATA'
export const GET_CAMPAIGN_STATS = 'GET_CAMPAIGN_STATS'
export const GET_CAMPAIGN_STAT_HOURS = 'GET_CAMPAIGN_STAT_HOURS'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN'
export const GET_AUDIENCE = 'GET_AUDIENCE'
export const CLEAR_CAMPAIGN_STATE = 'CLEAR_CAMPAIGN_STATE'
export const CLEAR_CAMPAIGN = 'CLEAR_CAMPAIGN'
export const CLEAR_CAMPAIGN_SURFACES = 'CLEAR_CAMPAIGN_SURFACES'
export const SET_CAMPAIGN_STRATEGY = 'SET_CAMPAIGN_STRATEGY'
export const CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS'
export const GET_CBU_SAMPLE_RATE = 'GET_CBU_SAMPLE_RATE'