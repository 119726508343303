export const USER_INFO = 'USER_INFO'
export const GET_ROLES = 'GET_ROLES'
export const GET_USERS = 'GET_USERS'
export const CREATE_NEW_USER = 'CREATE_NEW_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const PENDING_USERS = 'PENDING_USERS'
export const ACTIVATE_USER = 'ACTIVATE_USER'
export const DICTIONARY_AGENCY = 'DICTIONARY_AGENCY'
