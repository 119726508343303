import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth'),
  },
  {
    path: '/registration-user',
    name: 'RegistrationUser',
    component: () => import('../views/registration'),
  },
  {
    path: '/',
    name: 'AgencyList',
    component: () => import('../views/agency_list'),
    meta:{
      session_type: 'user'
    },
  },
  {
    path: '/root',
    component: () => import('../views/root/index'),
    children: [
      {
        path: '',
        name: 'root-agencies',
        meta:{
          title: 'Агенства',
          session_type: 'root'
        },
        component: () => import('../views/root/agencies')
      },
      {
        path: 'usersmanagment',
        name: 'root-usersmanagment',
        meta:{
          title: 'Пользователи',
          session_type: 'root'
        },
        component: () => import('../views/root/usersmanagment')
      },
      {
        path: 'table',
        name: 'root-table',
        meta:{
          title: 'Сводная таблица',
          session_type: 'root'
        },
        component: () => import('../views/root/table')
      },
      {
        path: 'report',
        name: 'root-report',
        meta:{
          title: 'Отчёты',
          session_type: 'root'
        },
        component: () => import('../views/root/report')
      }
    ]
  },
  {
    path: '/agency',
    component: () => import('../views/agency/index'),
    children: [
      {
        path: '',
        name: 'agency-advertiser',
        meta:{
          title: 'Рекламодатели',
          session_type: 'agency'
        },
        component: () => import('../views/agency/advertisers')
      },
      {
        path: 'usersmanagment',
        name: 'agency-usersmanagment',
        meta:{
          title: 'Пользователи',
          session_type: 'agency'
        },
        component: () => import('../views/agency/usersmanagment')
      },
      {
        path: 'table',
        name: 'agency-table',
        meta:{
          title: 'Сводная таблица',
          session_type: 'agency'
        },
        component: () => import('../views/agency/table')
      },
      {
        path: 'report',
        name: 'agency-reports',
        meta:{
          title: 'Отчёты',
          session_type: 'agency'
        },
        component: () => import('../views/agency/report')
      },
      {
        path: 'photo',
        name: 'radar-photo-surfaces',
        meta:{
          title: 'Фото поверхностей',
          session_type: 'agency'
        },
        component: () => import('../views/agency/photo/index')
      }
    ]
  },
  {
    path: '/radar',
    component: () => import('../views/radar/index'),
    children: [
      {
        path: '',
        name: 'radar-campaigns',
        meta:{
          title: 'Рекламные кампании',
          session_type: 'account'
        },
        component: () => import('../views/radar/campaigns')
      },
      {
        path: 'campaign/create',
        name: 'radar-campaign-create',
        meta:{
          title: 'Создание рекламной кампании',
          session_type: 'account'
        },
        component: () => import('../views/radar/campaing/create/index')
      },
      {
        path: 'campaign/:id/edit',
        name: 'radar-campaign-edit',
        meta:{
          title: 'Редактирование рекламной кампании',
          session_type: 'account'
        },
        component: () => import('../views/radar/campaing/_id/edit')
      },
      {
        path: 'campaign/:id',
        name: 'radar-campaign-id',
        meta: {
          session_type: 'account'
        },
        component: () => import('../views/radar/campaing/_id/index')
      },
      {
        path: 'creatives',
        name: 'radar-creatives',
        meta:{
          title: 'Список креативов',
          session_type: 'account'
        },
        component: () => import('../views/radar/creatives')
      },
      {
        path: 'inventory',
        name: 'radar-inventory',
        meta:{
          title: 'Рекламный инвентарь',
          session_type: 'account'
        },
        component: () => import('../views/radar/inventory/index'),
      },
      {
        path: 'inventory/create',
        name: 'radar-inventory-create',
        meta:{
          title: 'Новый список инвентаря',
          session_type: 'account'
        },
        component: () => import('../views/radar/inventory/create'),
      },
      {
        path: 'inventory/:id',
        name: 'radar-inventory-edit',
        meta:{
          title: 'Редактировать список',
          session_type: 'account'
        },
        component: () => import('../views/radar/inventory/_id'),
      },
      {
        path: 'reports',
        name: 'radar-reports',
        meta:{
          title: 'Отчёты',
          session_type: 'account'
        },
        component: () => import('../views/radar/reports/index')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if(to.name === 'Login' || to.name === 'RegistrationUser') {
    next()
    return;
  }
  if (sessionStorage.getItem("userSession")) {
    next()
  } else {
    next("/login?sessionIsNone=1")
  }
})


export default router
