import router from "@/router";
import { HTTP_POST } from "../const/request";
import {
  GET_CITIES,
  GET_PLATFORM_TYPES,
  GET_PUBLISHERS,
  GET_SURFACE_STATS,
  GET_UNITS,
  SET_CREATE_MODE,
  SET_CURRENT_CITY,
  SET_RATING,
  SET_TABLE_MODE,
  SET_VISIBLE_MARKERS,
} from "../const/map";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  namespaced: true,
  state: {
    cities: [],
    currentCity: "MSK",
    platforms: [],
    platform_types: [],
    publishers: [],
    units: [],
    formats: [
      {
        id: 54,
        name: "Outdoor",
      },
      {
        id: 1,
        name: "Indoor",
      },
      {
        id: 501,
        name: "Indoor",
      },
    ],
    surfacesStats: [],
    create_mode: "grid",
    table_mode: "selected",
    marker_mode: "all",
    rating: {
      min: 0,
      max: 0,
    },
  },
  getters: {
    getCities: (state) => state.cities,
    getCurrentCity: (state) => state.currentCity,
    getPlatformTypes: (state) => state.platform_types,
    getPublishers: (state) => state.publishers,
    getUnits: (state) => state.units,
    getFilteredUnits(state, getters, rootState, rootGetters) {
      const selectedSurface = rootGetters["Campaign/getSelectedSurfaces"];
      if (state.rating.max > 0 && state.surfacesStats.length) {
        return state.units.filter((unit) => {
          if (selectedSurface.find((surface) => surface.id == unit.id)) {
            return true;
          }
          return (
            unit.rating <= state.rating.max && unit.rating >= state.rating.min
          );
        });
      }
      if (selectedSurface.length > 0 && state.marker_mode === "selected") {
        return state.units.filter((unit) => {
          if (selectedSurface.find((surface) => surface.id === unit.id)) {
            return selectedSurface;
          }
        });
      }
      return state.units;
    },
    getFormats: (state) => state.formats,
    getPlatforms: (state) => state.platforms,
    getSurfacesStats: (state) => state.surfacesStats,
    getCreateMode: (state) => state.create_mode,
    getRating: (state) => state.rating,
    getTableMode: (state) => state.table_mode,
    getMarkerMode: (state) => state.marker_mode,
  },
  actions: {
    [GET_CITIES]: async ({ dispatch, rootState }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CITIES,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Map",
        },
        { root: true }
      );
    },
    [GET_PLATFORM_TYPES]: async ({ dispatch, rootState }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_PLATFORM_TYPES,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Map",
        },
        { root: true }
      );
    },
    [GET_PUBLISHERS]: async ({ dispatch, rootState }) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_PUBLISHERS,
          body: { session: rootState.Auth.session[session_type] },
          session_type,
          namespace: "Map",
        },
        { root: true }
      );
    },
    [GET_UNITS]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      payload.session = rootState.Auth.session[session_type];
      await dispatch(
        HTTP_POST,
        {
          method: GET_UNITS,
          body: payload,
          session_type,
          namespace: "Map",
        },
        { root: true }
      );
    },
    [GET_SURFACE_STATS]: async ({ dispatch, rootState, commit }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      let arraySurfaces = payload.surfaces;
      let chunkedSurfaces = [];
      let promises = [];
      const chunk = 100;
      for (let i = 0, j = arraySurfaces.length; i < j; i += chunk) {
        chunkedSurfaces.push(arraySurfaces.slice(i, i + chunk));
      }
      for (const index in chunkedSurfaces) {
        promises.push(
          dispatch(
            HTTP_POST,
            {
              method: GET_SURFACE_STATS,
              body: {
                ...payload,
                surfaces: chunkedSurfaces[index],
              },
              session_type,
              no_commit: true,
              namespace: "Map",
            },
            { root: true }
          )
        );
      }
      let results = await Promise.allSettled(promises);
      let statistics = results.map((result) => {
        if (result.value !== false) {
          return result.value.data.statistics
        }
      });
      statistics = statistics.flat();
      statistics = statistics.filter((stat) => stat);
      commit(GET_SURFACE_STATS, statistics);
    },
  },
  mutations: {
    [GET_CITIES]: (state, { data }) => {
      state.cities = data;
    },
    [GET_PLATFORM_TYPES]: (state, { data }) => {
      state.platform_types = data;
    },
    [GET_PUBLISHERS]: (state, { data }) => {
      state.publishers = data;
    },
    [GET_UNITS]: (state, { data }) => {
      state.units = data;
      state.platforms = data;
      if (state.units.length && state.surfacesStats.length) {
        data.map((unit, key) => {
          let index = state.surfacesStats.findIndex(
            (surface) => surface.surfaceId == unit.id
          );
          if (index !== -1) {
            state.units[key].rating = state.surfacesStats[index].rating;
          }
        });
      }
    },
    [SET_CREATE_MODE]: (state, payload) => {
      state.create_mode = payload;
    },
    [SET_TABLE_MODE]: (state, payload) => {
      state.table_mode = payload;
    },
    [GET_SURFACE_STATS]: (state, payload) => {
      state.surfacesStats = payload;
      if (
        state.units.length > 0 &&
        payload != null &&
        typeof payload[Symbol.iterator] === "function"
      ) {
        for (const surface of payload) {
          state.units[
            state.units.findIndex((unit) => unit.id == surface.surfaceId)
          ].rating = surface.rating;
        }
      }
    },
    [SET_RATING](state, payload) {
      state.rating = payload;
    },
    [SET_CURRENT_CITY](state, payload) {
      state.currentCity = payload;
    },
    [SET_VISIBLE_MARKERS]: (state, payload) => {
      state.marker_mode = payload;
    },
  },
};
