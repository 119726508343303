import moment from "moment";

const permissions = {
  install(Vue) {
    Vue.prototype.$checkPermission = (permissionName, store) => {
      if (store.state.Auth && store.state.Auth.permissions.length) {
        return store.state.Auth.permissions.includes(permissionName);
      }
      return false;
    };
  },
};

const openInNewTab = {
  install(Vue) {
    Vue.prototype.$openInNewTab = (link) => {
      let routeData = this.$router.resolve({ path: `/${link}` });
      window.open(routeData.href, "_blank");
    };
  },
};

const momentJs = {
  install(Vue) {
    Vue.prototype.$moment = moment;
  },
};

export { permissions, openInNewTab, momentJs };