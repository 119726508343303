import router from "@/router";
import { HTTP_POST } from "../const/request";
import {
  CREATE_CREATIVE,
  DEACTIVATE_CREATIVE,
  DELETE_CREATIVE,
  GET_CREATIVE_LIST,
  GET_CREATIVE_STATUSES,
  SET_CREATIVE_STATUS,
  UPDATE_CREATIVE,
  FILE_UPLOAD_CREATIVE,
} from "../const/creatives";

export default {
  namespaced: true,
  state: {
    tableData: [],
    creativesList: [],
    groups: [],
    creatives: [],
    creativeIDs: [],
  },
  getters: {
    getTableData: (state) => state.tableData,
    getCreativeList: (state) => state.creativesList,
    getCreatives: (state) => state.creatives,
    getCreativeIDs: (state) => state.creativeIDs,
    getGroups: (state) => state.groups,
  },
  actions: {
    [GET_CREATIVE_LIST]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CREATIVE_LIST,
          body: payload,
          session_type,
          namespace: "Creatives",
        },
        { root: true }
      );
    },
    [UPDATE_CREATIVE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: UPDATE_CREATIVE,
          body: payload,
          session_type,
          namespace: "Creatives",
          no_commit: true,
        },
        { root: true }
      );
    },
    [DELETE_CREATIVE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: DELETE_CREATIVE,
          body: payload,
          session_type,
          namespace: "Creatives",
          no_commit: true,
        },
        { root: true }
      );
    },
    [CREATE_CREATIVE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: CREATE_CREATIVE,
          body: payload,
          session_type,
          namespace: "Creatives",
          no_commit: true,
        },
        { root: true }
      );
    },
    [DEACTIVATE_CREATIVE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: DEACTIVATE_CREATIVE,
          body: payload,
          session_type,
          namespace: "Creatives",
          no_commit: true,
        },
        { root: true }
      );
    },
    [SET_CREATIVE_STATUS]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: SET_CREATIVE_STATUS,
          body: payload,
          session_type,
          namespace: "Creatives",
          no_commit: true,
        },
        { root: true }
      );
    },
    [GET_CREATIVE_STATUSES]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      await dispatch(
        HTTP_POST,
        {
          method: GET_CREATIVE_STATUSES,
          body: payload,
          session_type,
          namespace: "Creatives",
        },
        { root: true }
      );
    },
    [FILE_UPLOAD_CREATIVE]: async ({ dispatch, rootState }, payload) => {
      const session_type = router.currentRoute.meta.session_type;
      return await dispatch(
        HTTP_POST,
        {
          method: FILE_UPLOAD_CREATIVE,
          body: payload,
          session_type,
          namespace: "Creatives",
          no_commit: true,
          is_file: true,
        },
        { root: true }
      );
    },
  },
  mutations: {
    [GET_CREATIVE_LIST]: (state, { data, body }) => {
      if (body.campaignIDs) {
        state.creatives = data;
        state.creativeIDs = data.map((a) => {
          return a.id;
        });
        return;
      }
      state.creativesList = data;
      let groups = data
        .map((a) => {
          return a.group;
        })
        .filter((val, i, ar) => ar.indexOf(val) === i);

      let tableData = groups.map((a) => {
        const prevItem =
          state.tableData &&
          state.tableData[state.tableData.findIndex((i) => i.groupName === a)];
        return {
          groupName: a,
          show: prevItem ? prevItem.show : false,
          data: data.filter((item) => item.group === a),
        };
      });
      state.groups = groups;
      state.tableData = tableData;
    },
    [GET_CREATIVE_STATUSES]: (state, { data }) => {
      state.creatives = state.creatives.map((creative) => {
        return {
          ...creative,
          active: data.find(
            (creativePayload) => creative.id === creativePayload.creativeID
          ).active,
        };
      });
    },
  },
};
