import router from "@/router";
import { HTTP_POST } from "../const/request";
import { SET_ACCESSES } from "../const/auth";
import {
  ACTIVATE_USER,
  CHANGE_PASSWORD,
  CREATE_NEW_USER,
  GET_ROLES,
  GET_USERS,
  PENDING_USERS,
  REMOVE_USER,
  UPDATE_USER,
  UPDATE_USER_ACCESS,
  USER_INFO,
  DICTIONARY_AGENCY,
} from "../const/user";

const SortByField = (field) => (a, b) => {
    if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
    if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
    return 0;
};
export default {
  namespaced: true,
  state: {
    userInfo: {},
    roles: [],
    users: [],
    pending_users: [],
  },
  getters: {
    getUserInfo: (state) => state.userInfo,
    getRoles: (state) => state.roles,
    getUsers: (state) => state.users,
    getPendingUsers: (state) => state.pending_users,
  },
  actions: {
    [USER_INFO]: async ({ dispatch, rootState, commit }) => {
      const result = await dispatch(
        HTTP_POST,
        {
          method: USER_INFO,
          body: { session: rootState.Auth.session.user },
          namespace: "User",
        },
        { root: true }
      );
      if (rootState.loading[USER_INFO] !== "error") {
        commit("Auth/" + SET_ACCESSES, { data: result.data }, { root: true });
      }
    },
    [GET_ROLES]: async ({ dispatch, rootState }) => {
      await dispatch(
        HTTP_POST,
        {
          method: GET_ROLES,
          body: {
            session:
              rootState.Auth.session[router.currentRoute.meta.session_type],
          },
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
        },
        { root: true }
      );
    },

    [GET_USERS]: async ({ dispatch, rootState }) => {
      await dispatch(
        HTTP_POST,
        {
          method: GET_USERS,
          body: {
            session:
              rootState.Auth.session[router.currentRoute.meta.session_type],
          },
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
        },
        { root: true }
      );
    },

    [CREATE_NEW_USER]: async ({ dispatch, rootState }, payload) => {
      payload.session =
        rootState.Auth.session[router.currentRoute.meta.session_type];
      await dispatch(
        HTTP_POST,
        {
          method: CREATE_NEW_USER,
          body: payload,
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
          no_commit: true,
        },
        { root: true }
      );
    },
    [PENDING_USERS]: async ({ dispatch, rootState }) => {
      await dispatch(
        HTTP_POST,
        {
          method: PENDING_USERS,
          body: {
            session:
              rootState.Auth.session[router.currentRoute.meta.session_type],
          },
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
        },
        { root: true }
      );
    },
    [CHANGE_PASSWORD]: async ({ dispatch, rootState }, payload) => {
      return await dispatch(
        HTTP_POST,
        {
          method: CHANGE_PASSWORD,
          body: payload,
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
          no_commit: true,
        },
        { root: true }
      );
    },
    [UPDATE_USER_ACCESS]: async ({ dispatch, rootState }, payload) => {
      payload.session = rootState.Auth.session[router.currentRoute.meta.session_type]
      await dispatch(
        HTTP_POST,
        {
          method: UPDATE_USER_ACCESS,
          body: payload,
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
          no_commit: true,
        },
        { root: true }
      );
    },
    [UPDATE_USER]: async ({ dispatch, rootState }, payload) => {
      payload.session = rootState.Auth.session[router.currentRoute.meta.session_type]
      await dispatch(
        HTTP_POST,
        {
          method: UPDATE_USER,
          body: payload,
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
          no_commit: true,
        },
        { root: true }
      );
    },
    [REMOVE_USER]: async ({ dispatch, rootState }, payload) => {
      let body = payload || {};
      body.session = rootState.Auth.session[router.currentRoute.meta.session_type]
      await dispatch(
        HTTP_POST,
        {
          method: REMOVE_USER,
          body: body,
          namespace: "User",
          session_type: router.currentRoute.meta.session_type,
          no_commit: true,
        },
        { root: true }
      );
    },
    [ACTIVATE_USER]: async ({ dispatch, rootState }, payload) => {
      return await dispatch(
        HTTP_POST,
        {
          method: ACTIVATE_USER,
          body: payload,
          namespace: "User",
          no_commit: true,
        },
        { root: true }
      );
    },
    [DICTIONARY_AGENCY]: async ({ dispatch, rootState }) => {
      await dispatch(
        HTTP_POST,
        {
          method: DICTIONARY_AGENCY,
          body: { session: rootState.Auth.session.user },
          session_type: "user",
          namespace: "User",
        },
        { root: true }
      );
    },
  },
  mutations: {
    [USER_INFO]: (state, { data }) => {
      state.userInfo = data;
    },
    [GET_ROLES]: (state, { data }) => {
      state.roles = data.roles;
    },
    [GET_USERS]: (state, { data }) => {
      state.users = data.users;
    },
    [PENDING_USERS]: (state, { data }) => {
      state.pending_users = data;
    },
    [DICTIONARY_AGENCY]: (state, { data }) => {
      data = data.sort(SortByField("name"));
      state.agency_dictionary = data;
    },
  },
};
